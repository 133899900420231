import {captureException} from '@sentry/nextjs'
import {StoryblokComponent, useStoryblokState} from '@storyblok/react'
import type {GetStaticPaths, GetStaticProps} from 'next'
import dynamic from 'next/dynamic'
import {useRouter} from 'next/router'
import type {ReactElement} from 'react'
import {fetchAllCategoryPaths} from '../lib/commercetools/ct-api'
import {getProductDetailLayout} from '../lib/page-layouts/get-product-detail-layout'
import {getProductOverviewLayout} from '../lib/page-layouts/get-product-overview-layout'
import {getStoryPageLayout} from '../lib/page-layouts/get-story-page-layout'
import type {PageProps, StaticPathParams, StoryPageProps, TranslatedPath} from '../lib/page-props'
import {getProductDetailProps, getProductOverviewProps, getStoryPageProps} from '../lib/page-props'
import {fetchStoriesUrlMap} from '../lib/storyblok-api'
import {pathTranslations} from '../path-translations'

const ProductDetailPage = dynamic(() => import('../layouts/ProductDetailPage'))
const ProductsOverview = dynamic(() => import('../layouts/ProductOverviewPage'))

const StoryPageComponent = ({story, preview}: StoryPageProps) => {
  const {locale} = useRouter()
  story = useStoryblokState(story, {language: locale!}, preview)
  return <StoryblokComponent blok={story.content} />
}

export default function Page(props: PageProps) {
  if (props.type === 'product_overview') {
    return <ProductsOverview {...props} />
  }

  if (props.type === 'product_detail') {
    return <ProductDetailPage {...props} />
  }

  return <StoryPageComponent {...props} />
}

Page.getLayout = (page: ReactElement, props: PageProps, translatedPaths: TranslatedPath[]) => {
  if (props.type === 'product_overview') {
    return getProductOverviewLayout(page, props, translatedPaths)
  }

  if (props.type === 'product_detail') {
    return getProductDetailLayout(page, props, translatedPaths)
  }

  return getStoryPageLayout(page, props, translatedPaths)
}

export const getStaticProps: GetStaticProps = async context => {
  const locale = context.locale!

  if (locale === 'default') {
    return {
      notFound: true,
    }
  }

  const slugArray = (context.params?.slug ?? []) as string[]
  const lastSlug = slugArray.slice(-1)[0]
  const fullPath = `/${slugArray.join('/')}`

  try {
    // Product detail page
    if (lastSlug?.startsWith('p-')) {
      return await getProductDetailProps(context)
    }

    // Product overview page
    if (fullPath.startsWith(pathTranslations.products[locale]!)) {
      return await getProductOverviewProps(context)
    }

    // Story page
    return await getStoryPageProps(context)
  } catch (error) {
    console.error('Error while revalidating page: ', fullPath)
    console.error(error)
    captureException(error)
    return {
      notFound: true,
      revalidate: 600,
    }
  }
}

export const getStaticPaths: GetStaticPaths<StaticPathParams> = async context => {
  const locales = context.locales!.filter(locale => locale !== 'default')

  // Skip sitemap generation
  if (process.env.NEXT_PUBLIC_APP_ENV === 'local' && process.env.NODE_ENV === 'development') {
    console.debug('No static paths returned')
    return {
      paths: [],
      fallback: 'blocking',
    }
  }

  let paths: {params: StaticPathParams; locale: string}[] = []

  if (process.env.GENERATE_STORIES === 'true') {
    const storyPaths = await fetchStoriesUrlMap()
    paths.push(...storyPaths)
  }

  if (process.env.GENERATE_PRODUCT_OVERVIEWS === 'true') {
    const categoryPaths = await fetchAllCategoryPaths(locales)
    paths.push(...categoryPaths)
  }

  return {
    paths,
    fallback: 'blocking',
  }
}
